// @flow strict
import React from 'react';
import { css } from 'emotion';

import { teamContainer } from '../context';

type Props = {|
  name: string,
  number: number,
  position: string,
|};

const PlayerItem = (props: Props) => {
  function removePlayer() {
    teamContainer.removePlayerByNumber(props.number);
  }

  return (
    <li className={styles.listItem}>
      <div className={styles.playerNumber}>{props.number}</div>
      <div className={styles.playerName}>{props.name}</div>
      <button className={styles.remove} type="button" onClick={removePlayer}>
        x
      </button>
    </li>
  );
};

const styles = {
  listItem: css`
    box-sizing: border-box;
    display: flex;
    padding: 5px;

    :hover {
      background: #f5f5f5;

      button {
        visibility: visible;
      }
    }
  `,
  removeItem: css`
    visibility: hidden;
  `,
  playerNumber: css`
    margin-right: 10px;
    text-align: right;
    width: 25px;
  `,
  playerName: css`
    flex: 1;
  `,
  remove: css`
    background: none;
    border: none;
    cursor: pointer;
    font-weight: bold;
    visibility: hidden;
  `,
};

export default PlayerItem;
