// @flow strict
import React, { useState, useRef, useCallback } from 'react';
import { css } from 'emotion';

import type { PlayerPositions } from '../types/player.type';
import { colors } from '../styles';
import { teamContainer } from '../context';

type Props = {
  position: PlayerPositions,
};

type FormField = {
  value: string,
  error: boolean,
};

const PlayerForm = ({ position }: Props) => {
  const [name, setName] = useState({ value: '', error: false });
  const [number, setNumber] = useState({ value: '', error: false });

  const inputNumber: { current: null | HTMLInputElement } = useRef(null);

  const onChangeName = useCallback(
    ({ currentTarget }: SyntheticKeyboardEvent<HTMLInputElement>) => {
      setName({ value: currentTarget.value, error: false });
    },
    [name],
  );

  const onChangeNumber = useCallback(
    ({ currentTarget }: SyntheticKeyboardEvent<HTMLInputElement>) => {
      let value = isNaN(+currentTarget.value) ? number.value : currentTarget.value;
      setNumber({ value, error: false });
    },
    [],
  );

  const onKeyPress = useCallback(
    (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        let hasError = false;
        hasError = hasErrorInField(number.value, setNumber) ? true : hasError;
        hasError = hasErrorInField(name.value, setName) ? true : hasError;

        if (!hasError) {
          teamContainer.addPlayer({
            name: name.value,
            number: +number.value,
            position,
          });
          setName({ value: '', error: false });
          setNumber({ value: '', error: false });
          inputNumber.current && inputNumber.current.focus();
        }
      }
    },
    [name, number],
  );

  return (
    <div className={styles.addPlayerContainer}>
      <input
        className={`${styles.input(number.error)} ${styles.inputNumber}`}
        type="text"
        name="number"
        value={number.value}
        onChange={onChangeNumber}
        onKeyPress={onKeyPress}
        placeholder="#"
        ref={inputNumber}
        autoComplete="off"
      />
      <input
        className={`${styles.input(name.error)} ${styles.inputName}`}
        type="text"
        name="name"
        value={name.value}
        onChange={onChangeName}
        onKeyPress={onKeyPress}
        placeholder="Player name"
        autoComplete="off"
      />
    </div>
  );
};

function hasErrorInField(value: string, setState: FormField => void): boolean {
  if (!value) {
    setState({ value, error: true });
    return true;
  }
  return false;
}

const styles = {
  addPlayerContainer: css`
    label: players-group-add-player-container;
    display: flex;
    margin-bottom: 20px;
  `,
  input: error => css`
    label: players-group-input;
    border: none;
    border-bottom: 1px solid ${error ? colors.error : colors.border};
    font-size: 13px;
    flex: 1;
    height: 25px;
    outline: none;
  `,
  inputName: css`
    margin-left: 10px;
  `,
  inputNumber: css`
    max-width: 50px;
    text-align: center;
  `,
};

export default PlayerForm;
