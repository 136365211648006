// @flow strict
import React from 'react';
import { Subscribe } from 'unstated';

import type { Team } from '../types/team.type';

import { cloneTeam } from '../adapters/teams.adapter';
import { history } from '../App';
import { styledElements } from '../styles';
import { teamContainer, userContainer } from '../context';
import AdminOnly from '../utils/adminOnly';
import TeamContainer from '../context/team.container';

const TeamActions = () => {
  async function clone() {
    const team: Team = await cloneTeam(userContainer.state.uuid, teamContainer.getTeam());
    // TODO: Add message to team cloned succesfully
    history.push(`/teams/${team.uuid}`);
  }

  return (
    <Subscribe to={[TeamContainer]}>
      {team => (
        <ul className={styledElements.horizontalRightMenu}>
          {team.state.isOfficial && (
            <li>
              <button className={styledElements.buttonAsLink} type="button" onClick={clone}>
                Clone to my teams
              </button>
            </li>
          )}
          {!team.state.isOfficial && (
            <AdminOnly>
              <li>
                <button
                  className={styledElements.buttonAsLink}
                  type="button"
                  onClick={teamContainer.promote}
                >
                  Promote
                </button>
              </li>
            </AdminOnly>
          )}
          {team.state.isOfficial && (
            <AdminOnly>
              <li>
                <button
                  className={styledElements.buttonAsLink}
                  type="button"
                  onClick={teamContainer.demote}
                >
                  Demote
                </button>
              </li>
            </AdminOnly>
          )}
        </ul>
      )}
    </Subscribe>
  );
};

export default TeamActions;
