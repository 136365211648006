import React from 'react';

import { css, cx } from 'emotion';
import { Link } from 'react-router-dom';

export const colors = {
  textPrimary: '#333',
  textSecondary: '#929292',
  border: '#e9e9e9',
  // primary: '#00a1ff',
  primary: '#333',
  error: 'red',
};

export const fontSize = {
  main: '14px',
  navItem: '12px',
};

export const sectionStyles = {
  main: css`
    label: section-main;
    padding: 20px;
  `,
  subheader: css`
    label: subheader;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    padding: 20px;
  `,
};

const horizontalMenu = css`
  label: navbar-list;
  align-items: center;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  text-transform: capitalize;
`;

export const styledElements = {
  primaryButton: css`
    background: ${colors.primary};
    border-radius: 4px;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    padding: 8px 15px;
  `,
  buttonAsLink: css`
    background: none;
    border: none;
    color: ${colors.textSecondary};
    cursor: pointer;
    font-size: 14px;

    :hover {
      color: ${colors.textPrimary};
      text-decoration: underline;
    }
  `,
  horizontalRightMenu: css`
    ${horizontalMenu},
    li {
      margin-left: 30px;
    }
  `,
  horizontalLeftMenu: css`
    ${horizontalMenu},
    li {
      margin-right: 30px;
    }
  `,
};
export const styledComponents = {
  Link: props => (
    <Link
      to={props.to}
      className={cx(
        props.className,
        css`
          color: ${props.current === true ? colors.textPrimary : colors.textSecondary};
          &:hover {
            color: ${colors.textPrimary};
          }
        `,
      )}
    >
      {props.children}
    </Link>
  ),
};
