import _ from 'lodash';
import h337 from 'heatmap.js';
import ReactDOM from 'react-dom';
import React from 'react';
import PropTypes from 'prop-types';

class HeatmapLayer extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { cfg: null };
  }

  componentDidMount() {
    const { style, data, config } = this.props;
    let c = config || {};
    let _container = ReactDOM.findDOMNode(this);
    let defaultCfg = {
      width: style.width.replace('px', '') || _container.offsetWidth,
      height: style.height.replace('px', '') || _container.offsetHeight,
      blur: 0.65,
    };
    let _cfg = _.merge(defaultCfg, c);
    _cfg.container = _container;
    this.heatmapInstance = h337.create(_cfg);
    this.setState({ cfg: _cfg });
    this.heatmapInstance.setData(data);
  }

  componentWillReceiveProps(nextProps) {
    return nextProps !== this.props;
  }

  shouldComponentUpdate(nextProps) {
    return nextProps !== this.props;
  }

  render() {
    return <div ref="react-heatmap">{this.props.children}</div>;
  }
}

HeatmapLayer.propTypes = {
  /** Config object of the heatmap instance */
  config: PropTypes.object,
  /** Data that fillup the heatmap layer */
  data: PropTypes.object.isRequired,
  /** Style object of the heatmap layer */
  style: PropTypes.object.isRequired,
};

HeatmapLayer.defaultProps = {
  config: {},
  data: {
    data: [],
  },
  style: {
    width: '743px',
    height: '383px',
  },
};

export default HeatmapLayer;
