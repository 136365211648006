// @flow strict
import React from 'react';
import { Subscribe } from 'unstated';

import type { User } from '../types/user.type';

import Teams from './teams';
import TeamsContainer from '../context/teams.container';

type Props = {
  user: User,
  match: {
    params: { filter?: string },
  },
};

function TeamsWrapper({ user, match }: Props) {
  return (
    <Subscribe to={[TeamsContainer]}>
      {teams => (
        <Teams teams={teams.state.list} getTeams={teams.getAll} filter={match.params.filter} />
      )}
    </Subscribe>
  );
}

export default TeamsWrapper;
