// @flow strict
import React, { useCallback, useState } from 'react';
import { css } from 'emotion';

import { colors } from '../styles';
import PlayerForm from './playerForm';
import PlayerItem from './playerItem';

import type { Player, PlayerPositions } from '../types/player.type';

type Props = {|
  title: string,
  position: PlayerPositions,
  players: Player[],
|};

const PlayerList = (props: Props) => {
  const [isEditing, setIsEditing] = useState(!props.players.length);

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{props.title}</div>
        <button
          className={styles.editButton}
          onClick={useCallback(() => setIsEditing(!isEditing), [isEditing])}
          type="button"
        >
          edit
        </button>
      </div>
      <ul className={styles.list}>
        {props.players.map(player => (
          <PlayerItem key={player.number} {...player} />
        ))}
      </ul>
      {isEditing ? <PlayerForm position={props.position} /> : null}
    </div>
  );
};

const styles = {
  container: css`
    label: players-group-list-container;
    margin-bottom: 20px;
    max-width: 400px;
    width: 100%;
  `,
  list: css`
    label: players-group-list;
    list-style: none;
    margin-bottom: 20px;
    padding: 0;
  `,
  titleContainer: css`
    border-bottom: 2px solid ${colors.textPrimary};
    display: flex;
    justify-content: space-between;
  `,
  title: css`
    label: players-group-title;
    font-size: 12px;
    padding-bottom: 5px;
    text-transform: uppercase;
  `,
  editButton: css`
    label: players-list-edit;
    background: none;
    border: none;
    color: ${colors.textSecondary};
    cursor: pointer;
    font-size: 10px;

    :hover {
      color: ${colors.textPrimary};
    }
  `,
};

export default PlayerList;
