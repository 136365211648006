// @flow strict
import React, { useCallback, useRef, useEffect } from 'react';
import { css } from 'emotion';

type Props = {|
  name?: string,
  onChangeName: (name: string) => void,
|};

const TeamNameInput = ({ name, onChangeName }: Props) => {
  const inputRef: { current: null | HTMLInputElement } = useRef(null);

  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  }, []);

  const onChangeCallback = useCallback(
    ({ currentTarget }: SyntheticKeyboardEvent<HTMLInputElement>) => {
      onChangeName(currentTarget.value);
    },
  );

  return (
    <input
      type="text"
      placeholder="Add Your Team Name"
      className={styles.nameInput}
      name="name"
      value={name}
      onChange={onChangeCallback}
      ref={inputRef}
      autoComplete="off"
    />
  );
};

const styles = {
  nameInput: css`
    label: team-form-name-input;
    border: none;
    box-sizing: border-box;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 40px;
    outline: none;
    text-align: center;
    width: 100%;

    ::placeholder {
      color: #e0e0e0;
    }
  `,
};

export default TeamNameInput;
