import React from 'react';
import { css } from 'emotion';

import { history } from '../../App';
import { sectionStyles, styledElements } from '../../styles';
import { auth, googleAuthProvider } from '../../firebase';
import { withErrorHandling } from '../../utils/decorators';

class SignIn extends React.PureComponent {
  onSignIn = withErrorHandling(async () => {
    await auth.signInWithPopup(googleAuthProvider);
    history.push('/');
  });

  render() {
    return (
      <div className={styles.container}>
        <h1 className={styles.title}>Welcome back!</h1>
        <div className={sectionStyles.main}>
          <button className={styledElements.primaryButton} onClick={this.onSignIn}>
            Login with google
          </button>
        </div>
      </div>
    );
  }
}

const styles = {
  container: css`
    label: signin-container;
    text-align: center;
  `,
  title: css`
    ${sectionStyles.subheader}
    label: signin-title;
    display: inline-block;
    padding-top: 40px;
  `,
};

export default SignIn;
