// @flow strict
import { firestoreDb } from '../firebase';
import { getDefaultUser } from '../types/user.type';
import { InvalidData } from '../utils/errors';

import type { User } from '../types/user.type';

const userCollection = firestoreDb.collection('users');

export const createUser = async (
  uuid: string,
  email: string = '',
  name: string = '',
): Promise<User> => {
  if (!email) throw new InvalidData('invalid email');

  const created: number = new Date().getTime();
  const user: User = { ...getDefaultUser(), ...{ uuid, email, name, created, updated: created } };
  await userCollection.doc(uuid).set(user);
  return user;
};

export const getUserByEmail = async (email: string): Promise<?User> => {
  if (!email) throw new InvalidData('invalid email');

  const snapshot = await userCollection.where('email', '==', email).get();
  if (snapshot.docs.length === 0) return null;
  const user: User = snapshot.docs[0].data();
  return { ...getDefaultUser(), ...user };
};
