import React from 'react';
import PropTypes from 'prop-types';

const SoccerFieldSVG = props => (
  <svg width={0} height={0} {...props}>
    <title>field symbol</title>
    <g transform="translate(1 1)" fillRule="nonzero" fill={props.backgroundcolor || 'none'}>
      <path stroke={props.linescolor} d="M0 0h743v383H0z" />
      <ellipse stroke={props.linescolor} cx={372} cy={175.5} rx={43} ry={42.5} />
      <path d="M372.25 1.5v381" stroke={props.linescolor} strokeLinecap="square" />
      <path stroke={props.linescolor} d="M.5 99.5h97v156H.5zM645.5 97.5h97v156h-97z" />
    </g>
  </svg>
);

SoccerFieldSVG.propTypes = {
  /**
   * Width of the field
   */
  width: PropTypes.number.isRequired,
  /**
   * Height of the field
   */
  height: PropTypes.number,
  /**
   * BackgroundColor in hex
   */
  backgroundcolor: PropTypes.string,
  /**
   * Lines Color in hex
   */
  linescolor: PropTypes.string,
};

SoccerFieldSVG.defaultProps = {
  width: 745,
  height: 385,
  backgroundcolor: '#fff',
  linescolor: '#000',
};

/**
 * @component
 */
export default SoccerFieldSVG;
