import React from 'react';
import PropTypes from 'prop-types';
import HeatmapLayer from './HeatmapLayer';

function HeatmapLayerContainer(props) {
  return (
    <div className="heatmap-container">
      <HeatmapLayer data={props.data} style={props.style}>
        {props.children}
      </HeatmapLayer>
    </div>
  );
}

HeatmapLayerContainer.propTypes = {
  /** Data that fillup the heatmap layer */
  data: PropTypes.object.isRequired,
  /** Style object of the heatmap layer */
  style: PropTypes.object.isRequired,
};

HeatmapLayerContainer.defaultProps = {
  data: {
    data: [],
  },
  style: {
    width: '743px',
    height: '383px',
  },
};

export default HeatmapLayerContainer;
