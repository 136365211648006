// @flow strict

// $FlowFixMe
export const withErrorHandling = fn => (...args) =>
  fn(...args).catch(error => {
    console.log(error);

    // TODO: Handle InvalidData errors?

    // TODO: Handle auth errors - https://firebase.google.com/docs/reference/js/firebase.auth.Auth#signInWithPopup

    // Error page:
    // auth/auth-domain-config-required
    // auth/unauthorized-domain

    // Ignore - log it
    // cancelled-popup-request
    // auth/popup-closed-by-user

    // Toast message
    // auth/operation-not-allowed
    // auth/operation-not-supported-in-this-environment
    // auth/popup-blocked

    // TODO: handle auth/account-exists-with-different-credential error when multiple providers are added

    // Default: show toast / flash message - operation cannot be completed
  });
