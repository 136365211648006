// @flow strict
import { css } from 'emotion';

import { colors, fontSize } from '../../styles';

export default {
  list: css`
    label: navbar-list;
    align-items: center;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    text-transform: capitalize;

    li {
      margin-left: 30px;
    }
  `,
  navItem: css`
    label: navbar-item-link;
    align-items: center;
    background: transparent;
    border: none;
    color: ${colors.textSecondary};
    cursor: pointer;
    display: flex;
    font-size: ${fontSize.navItem};
    font-weight: bold;
    text-transform: uppercase;

    svg {
      fill: ${colors.textSecondary};
      margin-top: 3px;
    }

    :hover {
      color: ${colors.textPrimary};

      svg {
        fill: ${colors.textPrimary};
      }
    }
  `,
};
