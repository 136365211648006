// @flow strict
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import type { User } from '../types/user.type';

type Props = {
  path: string,
  component: {},
  user: User,
};

const PrivateRoute = (route: Props) => (
  <Route
    path={route.path}
    render={props =>
      route.user.uuid ? (
        <>
          {/* $FlowFixMe */}
          <route.component {...props} user={route.user} />
        </>
      ) : (
        <Redirect to="/signin" />
      )
    }
  />
);

export default PrivateRoute;
