//@flow strict
import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Provider, Subscribe } from 'unstated';
import createHistory from 'history/createBrowserHistory';

import { auth } from './firebase';
import { userContainer, teamsContainer, teamContainer } from './context';
import { getUserByEmail, createUser } from './adapters/user.adapter';
import UserContainer from './context/user.container';
import Heatmaps from './views/TapticsIndexView';
import SignIn from './auth/signin';
import Navbar from './common/navbar';
import Teams from './teams';
import TeamForm from './teamForm';
import PrivateRoute from './utils/privateRoute';

import type { User } from './types/user.type';

export const history = createHistory();

type State = {
  isLoading: boolean,
};

class App extends Component<{||}, State> {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    auth.onAuthStateChanged(async authenticatedUser => {
      if (authenticatedUser) {
        let user: ?User = await getUserByEmail(authenticatedUser.email);
        if (!user)
          user = await createUser(
            authenticatedUser.uid,
            authenticatedUser.email,
            authenticatedUser.displayName,
          );
        await userContainer.setInfo(user);
        this.setState({ isLoading: false });
      } else {
        userContainer.logout();
        this.setState({ isLoading: false });
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading ? (
          <div>Loading...</div>
        ) : (
          <Provider inject={[userContainer, teamsContainer, teamContainer]}>
            {/* $FlowFixMe */}
            <Router history={history}>
              <Subscribe to={[UserContainer]}>
                {user => (
                  <>
                    <Navbar user={user.state} />
                    <Switch>
                      <Route exact path="/" component={Heatmaps} />
                      <Route path="/signin" component={SignIn} />
                      <PrivateRoute
                        user={user.state}
                        path="/teams/:filter(official)"
                        component={Teams}
                      />
                      <PrivateRoute user={user.state} path="/teams/new" component={TeamForm} />
                      <PrivateRoute user={user.state} path="/teams/:teamId" component={TeamForm} />
                      <PrivateRoute user={user.state} path="/teams" component={Teams} />
                    </Switch>
                  </>
                )}
              </Subscribe>
            </Router>
          </Provider>
        )}
      </React.Fragment>
    );
  }
}

export default App;
