// @flow strict
import React, { useState, useEffect } from 'react';
import { css, cx } from 'emotion';
import debounce from 'lodash.debounce';

import { styledElements, sectionStyles } from '../styles';
import { history } from '../App';
import { teamContainer, teamsContainer } from '../context';
import TeamActions from './teamActions';
import TeamNameInput from './teamNameInput';
import TeamPlayers from './teamPlayers';

import type { Team } from '../types/team.type';
import type { Player } from '../types/player.type';

type Props = {
  user: string,
  teamToFetch: ?string,
  isLoading: boolean,
  uuid: string,
  name: string,
  players: Player[],
};

const debouncedUpdate = debounce(name => {
  teamContainer.update({ name });
}, 1000);

const TeamForm = (props: Props) => {
  const [name, setName] = useState('');

  async function getTeam() {
    const team: Team = await teamContainer.getTeamById(props.teamToFetch);
    if (!team) history.push('/teams');
  }

  useEffect(() => {
    if (props.teamToFetch === undefined) return;
    getTeam();
    return function() {
      teamContainer.reset();
    };
  }, [props.teamToFetch]);

  function onChangeName(teamName: string) {
    setName(teamName);
    debouncedUpdate(teamName);
  }

  async function onSubmit(event) {
    event.preventDefault();
    const team: ?Team = await teamsContainer.create(name);
    if (!team) return;
    teamContainer.setTeam(team);
    history.push(`/teams/${team.uuid}`);
  }

  return (
    <>
      {props.isLoading ? (
        <div>Loading team...</div>
      ) : (
        <>
          {props.uuid && (
            <div className={cx(sectionStyles.subheader, styles.subheadder)}>
              <TeamActions />
            </div>
          )}
          <form className={cx(sectionStyles.main, styles.container)} onSubmit={onSubmit}>
            <TeamNameInput name={name || props.name} onChangeName={onChangeName} />
            {props.uuid ? (
              <TeamPlayers players={props.players} />
            ) : (
              <div className={styles.controls}>
                <button type="submit" className={styledElements.primaryButton}>
                  Create
                </button>
              </div>
            )}
          </form>
        </>
      )}
    </>
  );
};

const styles = {
  container: css`
    label: team-form;
    margin: 0 auto;
    max-width: 800px;
  `,
  controls: css`
    label: team-form-controls;
    display: flex;
    justify-content: center;
  `,
  subheadder: css`
    justify-content: flex-end;
  `,
};

export default TeamForm;
