// @flow strict
import { Container } from 'unstated';

import { getDefaultTeam } from '../types/team.type';
import { getTeamByUuid, updateTeam } from '../adapters/teams.adapter';
import { userContainer } from '../context';

import type { Team } from '../types/team.type';
import type { Player } from '../types/player.type';

type State = {|
  isLoading: boolean,
  ...Team,
|};

type UpdateParams = {|
  uuid?: string,
  name?: string,
  players?: Player[],
  isOfficial?: boolean,
|};

const initialState = {
  isLoading: false,
  uuid: '',
  name: '',
  players: [],
  isOfficial: false,
  created: 0,
  updated: 0,
  location: '',
  user: '',
  logo: '',
};

// TODO: add validations to the params received by each function
class TeamContainer extends Container<State> {
  state = { ...initialState };

  getTeam(): Team {
    return {
      ...getDefaultTeam(),
      ...{
        uuid: this.state.uuid,
        name: this.state.name,
        players: this.state.players,
        isOfficial: this.state.isOfficial,
        created: this.state.created,
        updated: this.state.updated,
        location: this.state.location,
        user: this.state.user,
        logo: this.state.logo,
      },
    };
  }

  getTeamById = async (uuid: string): Promise<?Team> => {
    await this.setState({ isLoading: true });
    const team: ?Team = await getTeamByUuid(userContainer.state.uuid, uuid);
    if (!team) {
      await this.setState({ isLoading: false });
      return null; // TODO: throw custom error
    }
    await this.setState({ ...team, isLoading: false });
    return team;
  };

  update = async ({
    uuid = this.state.uuid,
    name = this.state.name,
    players = this.state.players,
    isOfficial = this.state.isOfficial,
  }: UpdateParams): Promise<void> => {
    const team: Team = { ...this.getTeam(), ...{ uuid, name, players, isOfficial } };
    const updatedTeam: ?Team = await updateTeam(userContainer.state.uuid, team);
    if (!updatedTeam) return; // TODO: throw custom error
    this.setState({ ...updatedTeam });
  };

  setTeam = (team: Team) => {
    this.setState({ ...team });
  };

  addPlayer = async (player: Player): Promise<void> => {
    const found: boolean = !!this.state.players.find(p => p.number === player.number);
    if (found) return; // // TODO: throw custom error

    const players: Player[] = [...this.state.players, player];
    await this.update({ players });
  };

  removePlayerByNumber = async (number: number): Promise<void> => {
    const index: number = this.state.players.findIndex(p => p.number === number);
    if (index === -1) return; // TODO: throw custom error

    const players: Player[] = [
      ...this.state.players.slice(0, index),
      ...this.state.players.slice(index + 1),
    ];
    this.update({ players });
  };

  promote = async (): Promise<void> => {
    if (!userContainer.state.isAdmin) return; //TODO throw custom error
    this.update({ isOfficial: true });
  };

  demote = async (): Promise<void> => {
    if (!userContainer.state.isAdmin) return; //TODO throw custom error
    this.update({ isOfficial: false });
  };

  reset = () => {
    this.setState({ isLoading: false, ...initialState });
  };
}

export default TeamContainer;
