// @flow strict
import React from 'react';
import { css } from 'emotion';

import { styledComponents } from '../../styles';
import { history } from '../../App';
import styles from './navbar.styles';

const { Link } = styledComponents;

type Props = {
  signOut: () => {},
  location: {
    pathname: string,
  },
};

class UserNavbar extends React.PureComponent<Props> {
  render() {
    const currentRoute = this.props.location.pathname;

    return (
      <React.Fragment>
        <ul className={`${styles.list} ${centerList}`}>
          <li>
            <Link to="plays" className={styles.navItem}>
              plays
            </Link>
          </li>
          <li>
            <Link
              to="/teams"
              current={currentRoute.indexOf('/teams') !== -1}
              className={styles.navItem}
            >
              teams
            </Link>
          </li>
        </ul>
        <ul className={`${styles.list} ${rightList}`}>
          <li>
            <button className={styles.navItem} onClick={this.signOut}>
              Logout
            </button>
          </li>
        </ul>
      </React.Fragment>
    );
  }

  signOut = () => {
    this.props.signOut();
    history.push('/');
  };
}

const centerList = css`
  justify-content: center;
`;

const rightList = css`
  justify-content: flex-end;
`;

export default UserNavbar;
