// @flow strict
import type { Player } from './player.type';

export type Team = {|
  uuid: string,
  name: string,
  user: string,
  players: Player[],
  logo: string,
  location: string,
  created: number,
  updated: number,
  isOfficial: boolean,
|};

export const getDefaultTeam = (): Team => ({
  uuid: '',
  name: '',
  user: '',
  players: [],
  logo: '',
  location: '',
  created: 0,
  updated: 0,
  isOfficial: false,
});
