// @flow strict
import React from 'react';
import { css } from 'emotion';
import { Link } from 'react-router-dom';

import { colors } from '../../styles';

type Props = {|
  id: string,
  name: string,
|};

class TeamItem extends React.Component<Props> {
  render() {
    return (
      <li className={styles.container}>
        <Link className={styles.link} to={`/teams/${this.props.id}`}>
          {this.props.name}
        </Link>
      </li>
    );
  }
}

const ITEM_HEIGHT = 75;
const styles = {
  container: css`
    label: teamItem-container;
    align-items: center;
    background-color: transparent;
    border: 1px solid ${colors.border};
    border-left: 4px solid ${colors.primary};
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    height: ${ITEM_HEIGHT}px;
    list-style: none;
    justify-content: center;
    padding: 0 20px;
    text-transform: capitalize;
    transition: background-color 0.5s ease;

    :hover {
      background-color: ${colors.primary};
    }
  `,
  link: css`
    label: teamItem-link
    align-content: center;
    color: ${colors.textPrimary};
    height: ${ITEM_HEIGHT}px;
    line-height: ${ITEM_HEIGHT}px;
    text-align: center;
    width: 100%;

    :hover {
      color: #fff;
    }
  `,
};

export default TeamItem;
