// @flow strict
import { Container } from 'unstated';

import { createTeam, getAllTeams, getOfficialTeams } from '../adapters/teams.adapter';
import { userContainer } from '../context';

import type { Team } from '../types/team.type';

type State = {|
  list: Team[],
|};

class TeamsContainer extends Container<State> {
  state = {
    list: [],
  };

  create = async (name: string): Promise<?Team> => {
    if (!name) return; // TODO: throw custom error
    let team: Team = await createTeam(userContainer.state.uuid, name);
    this.setState({ list: [...this.state.list, team] });
    return team;
  };

  getAll = async (filter?: string): Promise<void> => {
    const getTeams = filter === 'official' ? getOfficialTeams : getAllTeams;
    let teams: Team[] = await getTeams(userContainer.state.uuid);
    this.setState({ list: teams });
  };
}

export default TeamsContainer;
