// @flow strict
import React from 'react';
import { css } from 'emotion';
import { Link } from 'react-router-dom';

import styles from './navbar.styles';

function AnonymousNav() {
  return (
    <ul
      className={css`
        ${styles.list};
        padding-right: 20px;
      `}
    >
      <li>
        <Link to="signin" className={styles.navItem}>
          Sign in
        </Link>
      </li>
      <li>
        <Link to="signup" className={styles.navItem}>
          Sign up
        </Link>
      </li>
    </ul>
  );
}

export default AnonymousNav;
