// @flow strict
import * as React from 'react';
import { Subscribe } from 'unstated';

import UserContainer from '../context/user.container';

type Props = {
  children: React.Node,
};

const AdminOnly = ({ children }: Props) => (
  <Subscribe to={[UserContainer]}>{user => <>{user.state.isAdmin ? children : null}</>}</Subscribe>
);

export default AdminOnly;
