// @flow strict
import { generate } from 'short-uuid';

import { firestoreDb } from '../firebase';
import { getDefaultTeam } from '../types/team.type';

import type { Team } from '../types/team.type';

const teamCollection = firestoreDb.collection('teams');

function filterTeamFields(team: Team) {
  return {
    uuid: team.uuid,
    name: team.name,
  };
}

export const createTeam = async (user: string, name: string): Promise<Team> => {
  let uuid: string = generate();
  let created: number = new Date().getTime();
  let team: Team = { ...getDefaultTeam(), ...{ uuid, user, name, created, updated: created } };
  // TODO: if this errors, show error page
  await teamCollection.doc(uuid).set(team);
  return team;
};

export const cloneTeam = async (user: string, team: Team): Promise<Team> => {
  let uuid: string = generate();
  let created: number = new Date().getTime();
  let newTeam: Team = { ...team, ...{ uuid, user, created, updated: created, isOfficial: false } };
  await teamCollection.doc(uuid).set(newTeam);
  return newTeam;
};

export const updateTeam = async (user: string, team: Team): Promise<?Team> => {
  if (!team.uuid) return null; // TODO throw custom error
  const snapshot = await teamCollection
    .where('user', '==', user)
    .where('uuid', '==', team.uuid)
    .get();

  if (snapshot.docs.length === 0) return null;

  const updatedTeam: Team = { ...team, ...{ updated: new Date().getTime() } };
  await teamCollection.doc(snapshot.docs[0].id).update(updatedTeam);
  return { ...updatedTeam };
};

export const getAllTeams = async (user: string): Promise<Team[]> => {
  let snapshot = await teamCollection
    .where('user', '==', user)
    .where('isOfficial', '==', false)
    .orderBy('created', 'desc')
    .get();

  return snapshot.docs.map(doc => ({ ...filterTeamFields(doc.data()) }));
};

export const getOfficialTeams = async (user: string): Promise<Team[]> => {
  let snapshot = await teamCollection
    .where('user', '==', user)
    .where('isOfficial', '==', true)
    .orderBy('created', 'desc')
    .get();

  return snapshot.docs.map(doc => ({ ...filterTeamFields(doc.data()) }));
};

export const getTeamByUuid = async (user: string, uuid: string): Promise<?Team> => {
  const snapshot = await teamCollection
    .where('user', '==', user)
    .where('uuid', '==', uuid)
    .get();

  if (snapshot.docs.length === 0) return null;
  const docData = snapshot.docs[0].data();
  if (!docData) return null;
  return { ...getDefaultTeam(), ...docData };
};
