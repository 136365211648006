// @flow strict
import React, { useEffect } from 'react';
import { css } from 'emotion';

import TeamItem from './teamItem';
import { sectionStyles, colors, styledElements, styledComponents } from '../styles';
import { history } from '../App';
import type { Team } from '../types/team.type';

const { Link } = styledComponents;

function navigateToForm() {
  history.push('/teams/new');
}

type Props = {
  teams: Team[],
  getTeams: (filter?: string) => Promise<void>,
  filter?: string,
};

const Teams = (props: Props) => {
  useEffect(() => {
    props.getTeams(props.filter);
  }, [props.filter]);

  return (
    <>
      <div className={`${sectionStyles.subheader} ${styles.subheadder}`}>
        <ul className={styledElements.horizontalLeftMenu}>
          <li>
            <Link to="/teams" current={props.filter === undefined}>
              {' '}
              My teams
            </Link>
          </li>
          <li>
            <Link to="/teams/official" current={props.filter === 'official'}>
              {' '}
              Official teams
            </Link>
          </li>
        </ul>
        <button className={styles.newTeamButton} type="button" onClick={navigateToForm}>
          <span className={styles.plusIcon}>{PlusIcon()}</span>
          team
        </button>
      </div>
      <ul className={`${sectionStyles.main} ${styles.list}`}>
        {props.teams.map((team: Team) => (
          <TeamItem key={team.uuid} id={team.uuid} name={team.name} />
        ))}
      </ul>
    </>
  );
};

const styles = {
  subheadder: css`
    /* justify-content: flex-end; */
    justify-content: space-between;
  `,
  newTeamButton: css`
    align-items: center;
    background: #fff;
    border: 1px solid ${colors.border};
    border-radius: 20px;
    box-sizing: border-box;
    color: ${colors.textSecondary};
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 30px;
    padding: 0 15px;
    text-transform: lowercase;

    :hover {
      color: ${colors.textPrimary};
      font-weight: bold;

      svg {
        fill: ${colors.primary};
      }
    }
  `,
  plusIcon: css`
    svg {
      fill: ${colors.textSecondary};
      margin-right: 5px;
    }
  `,
  list: css`
    label: team-list;
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 2fr));
  `,
  current: css``,
};

function PlusIcon() {
  return (
    <svg height="13px" viewBox="0 0 16 16" width="13px">
      <path d="M15,6h-5V1c0-0.55-0.45-1-1-1H7C6.45,0,6,0.45,6,1v5H1C0.45,6,0,6.45,0,7v2c0,0.55,0.45,1,1,1h5v5c0,0.55,0.45,1,1,1h2  c0.55,0,1-0.45,1-1v-5h5c0.55,0,1-0.45,1-1V7C16,6.45,15.55,6,15,6z" />
    </svg>
  );
}

export default Teams;
