// @flow strict
export type User = {|
  uuid: string,
  email: string,
  name: string,
  isAdmin: boolean,
  isManager: boolean,
  created: number,
  updated: number,
|};

export const getDefaultUser = (): User => ({
  uuid: '',
  email: '',
  name: '',
  isAdmin: false,
  isManager: false,
  created: 0,
  updated: 0,
});
