// @flow strict
import React from 'react';
import { css } from 'emotion';
import { Link, withRouter } from 'react-router-dom';

import { auth } from '../../firebase';
import { colors } from '../../styles';
import UserNav from './userNavbar';
import AnonymousNav from './anonymousNavbar';

import type { User } from '../../types/user.type';

const UserNavWithRouter = withRouter(props => <UserNav {...props} />);

type Props = {
  user: User,
};

function Navbar({ user }: Props) {
  return (
    <nav className={styles.container}>
      <h1 className={styles.title}>
        <Link to="/">taptics</Link>
      </h1>
      {user.email ? <UserNavWithRouter signOut={auth.signOut.bind(auth)} /> : <AnonymousNav />}
    </nav>
  );
}

const NAVBAR_HEIGHT = 50;
const styles = {
  container: css`
    label: navbar-container;
    border-bottom: 1px solid ${colors.border};
    height: ${NAVBAR_HEIGHT}px;
    display: flex;
    padding: 0 20px;
    text-transform: uppercase;

    > * {
      flex: 1;
    }
  `,
  title: css`
    label: navbar-title;
    align-self: center;
    display: flex;
    margin: 0;

    > a {
      color: ${colors.textPrimary};
      font-weight: bold;
    }
  `,
};

// eslint-disable-next-line
function getDropdownIcon() {
  return (
    <svg height="22" viewBox="0 0 48 48" width="22" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.83 16.42l9.17 9.17 9.17-9.17 2.83 2.83-12 12-12-12z" />
      <path d="M0-.75h48v48h-48z" fill="none" />
    </svg>
  );
}

export default Navbar;
