// @flow strict
import { Container } from 'unstated';

import type { User } from '../types/user.type';

const initialState: User = {
  uuid: '',
  email: '',
  name: '',
  isAdmin: false,
  isManager: false,
  created: 0,
  updated: 0,
};

class UserContainer extends Container<User> {
  state = { ...initialState };

  setInfo(user: User) {
    return this.setState({ ...user });
  }

  logout() {
    this.setState({ ...initialState });
  }
}

export default UserContainer;
