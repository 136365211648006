// @flow strict

export const PlayerPosition = {
  GOALKEEPER: 'goalkeeper',
  DEFENDER: 'defender',
  MIDFIELDER: 'midfielder',
  FORWARD: 'forward',
};

export type PlayerPositions = 'goalkeeper' | 'defender' | 'midfielder' | 'forward';

export type Player = {|
  number: number,
  name: string,
  position: PlayerPositions,
|};
