// @flow strict
import React from 'react';
import { css } from 'emotion';

import PlayerList from './playerList';
import { PlayerPosition } from '../types/player.type';

import type { Player } from '../types/player.type';

type Props = {|
  players: Player[],
|};

function getPlayersByPosition(players: Player[]) {
  return {
    goalkeepers: players.filter((p: Player) => p.position === PlayerPosition.GOALKEEPER),
    defenders: players.filter((p: Player) => p.position === PlayerPosition.DEFENDER),
    midfielders: players.filter((p: Player) => p.position === PlayerPosition.MIDFIELDER),
    forwards: players.filter((p: Player) => p.position === PlayerPosition.FORWARD),
  };
}

const TeamPlayers = (props: Props) => {
  const positions = getPlayersByPosition(props.players);
  return (
    <div className={styles.container}>
      <PlayerList
        title="goalkeepers"
        position={PlayerPosition.GOALKEEPER}
        players={positions.goalkeepers}
      />
      <PlayerList
        title="defenders"
        position={PlayerPosition.DEFENDER}
        players={positions.defenders}
      />
      <PlayerList
        title="midfielders"
        position={PlayerPosition.MIDFIELDER}
        players={positions.midfielders}
      />
      <PlayerList title="forwards" position={PlayerPosition.FORWARD} players={positions.forwards} />
    </div>
  );
};

const styles = {
  container: css`
    label: team-form-players-container;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-left: 40px;
  `,
};

export default TeamPlayers;
