import React from 'react';
import PropTypes from 'prop-types';
import SoccerFieldSVG from './SoccerFieldSVG';
import HeatmapLayerContainer from './HeatmapLayerContainer';

function FootballPitch(props) {
  return (
    <div className="football-pitch">
      <HeatmapLayerContainer data={props.heatmapData}>
        <SoccerFieldSVG
          linescolor={props.pitchLinesColor}
          backgroundcolor={props.pitchBackgroundColor}
        />
      </HeatmapLayerContainer>
    </div>
  );
}

FootballPitch.propTypes = {
  pitchLinesColor: PropTypes.string,
  pitchBackgroundColor: PropTypes.string,
  heatmapData: PropTypes.object.isRequired,
};

FootballPitch.defaultProps = {
  pitchLinesColor: '#000',
  pitchBackgroundColor: '#FFF451',
  heatmapData: {
    data: {},
  },
};

export default FootballPitch;
