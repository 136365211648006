import React from 'react';

import { sectionStyles } from '../styles';
import FootballPitch from '../components/FootballPitch';

function generatePoints(maxPoints, width, height) {
  var dataPoints = {};
  // randomly generate extremas
  var extremas = [(Math.random() * 100) >> 0, (Math.random() * 100) >> 0];
  var max = Math.max.apply(Math, extremas);
  var min = Math.min.apply(Math, extremas);
  var t = [];
  for (var i = 0; i < maxPoints; i++) {
    var x = (Math.random() * width) >> 0;
    var y = (Math.random() * height) >> 0;
    var c = ((Math.random() * max - min) >> 0) + min;
    // btw, we can set a radius on a point basis
    var r = 40; //(Math.random()* 30) >> 0;
    // add to dataset
    t.push({ x: x, y: y, value: c, radius: r });
  }

  dataPoints = {
    min: min,
    max: max,
    data: t,
  };
  // set the generated dataset
  // heatmap.setData({
  // min: min,
  // max: max,
  // data: t
  // });
  //console.log('took ', (+new Date) - init, 'ms');
  // dataPointsObjects.max = 100;
  // dataPointsObjects.min = 0;
  // dataPointsObjects.data = dataPoints;
  return dataPoints;
}

function TapticsIndexView(props) {
  var maxPoints = 400;
  var width = 743;
  var height = 383;
  var dataObject = generatePoints(maxPoints, width, height);
  // console.log('points:generated:', dataObject);

  return (
    <div className={sectionStyles.main}>
      <FootballPitch heatmapData={dataObject} />
      <FootballPitch
        heatmapData={dataObject}
        pitchLinesColor="#fff"
        pitchBackgroundColor="#7a0096"
      />
    </div>
  );
}

export default TapticsIndexView;
