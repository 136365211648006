// @flow strict
import React from 'react';
import { Subscribe } from 'unstated';

import TeamContainer from '../context/team.container';
import TeamForm from './teamForm';

import type { User } from '../types/user.type';

type Props = {|
  match: {
    params: {
      teamId?: string,
    },
  },
  user: User,
|};

const TeamFormWrapper = (props: Props) => (
  <Subscribe to={[TeamContainer]}>
    {team => (
      <TeamForm
        user={props.user.uuid}
        teamToFetch={props.match.params.teamId}
        isLoading={team.state.isLoading}
        uuid={team.state.uuid}
        name={team.state.name}
        players={team.state.players}
      />
    )}
  </Subscribe>
);

export default TeamFormWrapper;
